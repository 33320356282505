
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { StrategyVariationSelectorItem } from "@/components/StrategyOptimizer/model/StrategyVariationSelectorItem";

@Component()
export default class StrategyParamVariationsForm extends Vue {
  @Prop()
  selectorItem?: StrategyVariationSelectorItem;

  inputValue($event, index) {
    this.selectorItem.strategyVariation.paramVariations[index] = $event.split(",").map((item) => parseFloat(item));
  }
}
