import { GetStrategyResponseStrategy, StrategyVariation } from "api/models";

export class StrategyVariationSelectorItem {
  dialog = false;

  constructor(
    public name: string,
    public strategyVariation: StrategyVariation,
    public template: GetStrategyResponseStrategy
  ) {}

  hasParameter() {
    return this.template.params.length > 0;
  }
}
