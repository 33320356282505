
import Component from "vue-class-component";
import Vue from "vue";
import OptimizeResultTable from "@/components/StrategyOptimizer/OptimizeResultTable.vue";
import StrategyOptimizerDetails from "@/components/StrategyOptimizer/StrategyOptimizerDetails.vue";
import { marketApi, strategyOptimizerApi } from "@/plugins/api";
import { Market } from "api/models";
import { map } from "rxjs/operators";
import StrategyParamVariationsForm from "@/components/StrategyOptimizer/StrategyParamVariationsForm.vue";
import { StrategyVariationSelectorItem } from "@/components/StrategyOptimizer/model/StrategyVariationSelectorItem";
import { GetAppStrategyoptimizerGetoneRequest, PostAppStrategyoptimizerCreateRequest } from "next-trader-api";

@Component({
  components: {
    StrategyParamVariationsForm,
    StrategyOptimizerDetails,
    OptimizeResultTable,
  },
})
export default class StrategyOptimizerNew extends Vue {
  from = "2017-01-01";
  to = "2020-12-31";
  candleSize = "1h";
  marketIds = [6, 7];
  entryStrategyVariations: Array<StrategyVariationSelectorItem> = [];
  exitStrategyVariations: Array<StrategyVariationSelectorItem> = [];

  strategyOptimizeId = this.$router.currentRoute.params.strategyOptimizeId;

  markets: Array<Market> = [];

  allEntryStrategyVariations: Array<StrategyVariationSelectorItem> = [];
  allExitStrategyVariations: Array<StrategyVariationSelectorItem> = [];

  dialogWidth = 500;

  created() {
    this.getMarkets$().subscribe();
    this.getStrategies$()
      .pipe(
        map((next) => {
          if (this.strategyOptimizeId) {
            const request: GetAppStrategyoptimizerGetoneRequest = {
              strategyOptimizeId: parseInt(this.strategyOptimizeId),
            };
            strategyOptimizerApi.getAppStrategyoptimizerGetone(request).subscribe((next) => {
              this.from = next.fromDate.substr(0, 10);
              this.to = next.toDate.substr(0, 10);
              this.candleSize = next.candleSize;
              this.marketIds = next.strategyOptimizeLinkMarkets.map((item) => item.market.id);
              this.entryStrategyVariations = next.strategyOptimizeLinkEntryStrategies.map((item) => {
                const selectorItem = this.allEntryStrategyVariations.find(
                  (_item) => _item.strategyVariation.name === item.entryStrategy.name
                );
                selectorItem.strategyVariation.paramVariations = item.paramVariations;
                return selectorItem;
              });
              this.exitStrategyVariations = next.strategyOptimizeLinkExitStrategies.map((item) => {
                const selectorItem = this.allExitStrategyVariations.find(
                  (_item) => _item.strategyVariation.name === item.exitStrategy.name
                );
                selectorItem.strategyVariation.paramVariations = item.paramVariations;
                return selectorItem;
              });
            });
          }
          return next;
        })
      )
      .subscribe();
  }

  getHeaders(): Array<{ text: string; value: string }> {
    return [{ text: "Name", value: "name" }];
  }

  getEntryStrategyVariations() {
    return this.entryStrategyVariations.filter((item) => !item.name.startsWith("Candle"));
  }

  getEntryCandlePatternStrategyVariations() {
    return this.entryStrategyVariations.filter((item) => item.name.startsWith("Candle"));
  }

  getStrategies$() {
    return strategyOptimizerApi.getAppStrategyoptimizerGetstrategies().pipe(
      map((next) => {
        this.allEntryStrategyVariations = next.entryStrategies.map((template) => {
          return new StrategyVariationSelectorItem(
            template.name,
            {
              name: template.name,
              paramVariations: template.defaultParamVariations,
            },
            template
          );
        });

        this.allExitStrategyVariations = next.exitStrategies.map((template) => {
          return new StrategyVariationSelectorItem(
            template.name,
            {
              name: template.name,
              paramVariations: template.defaultParamVariations,
            },
            template
          );
        });
      })
    );
  }

  getMarkets$() {
    return marketApi.getAppMarketGetall().pipe(
      map((next) => {
        this.markets = next;
      })
    );
  }

  save() {
    if (this.strategyOptimizeId) {
      alert("TODO: Save does NOT work when edit strategyOptimize");

      this.$router.push({
        name: "strategyOptimizer",
        params: { strategyOptimizeId: this.strategyOptimizeId },
      });

      return;
    }

    const request: PostAppStrategyoptimizerCreateRequest = {
      optimizeCreateRequest: {
        from: this.from,
        to: this.to,
        candleSize: this.candleSize,
        marketIds: this.marketIds,
        entryStrategyVariations: this.entryStrategyVariations.map((item) => item.strategyVariation),
        exitStrategyVariations: this.exitStrategyVariations.map((item) => item.strategyVariation),
      },
    };

    strategyOptimizerApi.postAppStrategyoptimizerCreate(request).subscribe((next) => {
      this.$router.push({
        name: "strategyOptimizer",
        params: { strategyOptimizeId: next.id },
      });
    });
  }

  getChipTooltipText(selectorItem: StrategyVariationSelectorItem) {
    if (!selectorItem.hasParameter()) {
      return "--- no parameter ---";
    }

    return selectorItem.template.params
      .map((item, index) => {
        return item.name + ": " + selectorItem.strategyVariation.paramVariations[index].join(", ");
      })
      .join("<br>");
  }
}
